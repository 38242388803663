<template>
  <div class="bg-white">
    <slot />
  </div>
</template>

<script lang="ts" setup>
const $config = useRuntimeConfig()

/** start * these need to be run in order to capture ssrContext for the client */
useAnonymousId()
useRtSessionId()
useIsBot()
useGeolocation()
const { experiments } = useExperiment()
/** end */

const bodyScroll = useBodyScroll()

if (experiments?.maintenanceMode.value) {
  throw createError({ statusCode: 503, statusMessage: 'Maintenance Mode' })
}

useServerHead({
  script: [
    {
      key: 'segment',
      type: 'text/javascript',
      innerHTML: segmentScript($config.public.segment)
    },
    {
      key: 'datadog-rum',
      type: 'text/javascript',
      innerHTML: datadogRumScript({ $config: $config.public.datadogRum, $experiment: experiments ?? {} })
    }
  ]
})

watch(
  () => bodyScroll.value,
  (v) => {
    if (v) {
      document.body.style.removeProperty('overflow')
    } else {
      document.body.style.setProperty('overflow', 'hidden')
    }
  }
)
</script>
